

























































@import '@design';

.footer-loc {
  .r-title {
    line-height: 1.4;
  }
  .location--phone-link {
    color: white;
    text-decoration: none;
  }
  .footer-hours {
    margin: 0.5em auto;
    white-space: pre;
  }
}
